import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { RiLoader5Line } from "react-icons/ri"
import { connect, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"

import {
  LayoutQuoting,
  Title,
  SectionCenter,
  PlanDisplayCard,
  PrimaryButton,
  SecondaryButton,
  GoBack,
  PaymentCard,
  PaymentCardDestination,
  Spinner
} from "../../components"
import { getCurrentPath, setPayment } from "../../redux/actions"
import {
  validPaymentInfo,
  validPayment,
  setPurchasePayload,
} from "../../utils/helpers"

const Payment = ({
  location,
  getCurrentPath,
  selectedPlan,
  canProceedFromUI,
  travelPlan,
  travellers,
  coverage,
  deductible,
  purchaseConfirmation,
  purchaseResult,
  famRate,
  options,
  quotes,
  apiCallStatus
}) => {
  const intl = useIntl()
  const [isValidPlan, setIsValidPlan] = useState(true)
  const [isValidPaymentInfo, setIsValidPaymentInfo] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const [purchaseFailed, setPurchaseFailed] = useState(false);
  const [destinationResponse, setDestinationResponse] = useState(undefined);
  const dispatch = useDispatch()

  useEffect(() => {
    getCurrentPath(location.pathname)
    setIsValidPlan(selectedPlan !== null)
    setIsValidPaymentInfo(validPaymentInfo())
  }, [getCurrentPath, location.pathname])

  const onMakePurchase = () => {

    if (!validPayment() || !canProceedFromUI) return
    const purchasePayload = setPurchasePayload(
      typeof window !== "undefined" && window.sessionStorage.getItem("address"),
      typeof window !== "undefined" && window.sessionStorage.getItem("city"),
      typeof window !== "undefined" &&
      window.sessionStorage.getItem("province"),
      typeof window !== "undefined" &&
      window.sessionStorage.getItem("postalCode"),
      typeof window !== "undefined" && window.sessionStorage.getItem("email"),
      typeof window !== "undefined" && window.sessionStorage.getItem("phone"),
      travelPlan,
      travellers,
      coverage,
      deductible,
      purchaseConfirmation,
      typeof window !== "undefined" &&
      window.sessionStorage.getItem("cardHolderName"),
      typeof window !== "undefined" &&
      window.sessionStorage.getItem("cardNumber"),
      typeof window !== "undefined" &&
      window.sessionStorage.getItem("expireMonth"),
      typeof window !== "undefined" &&
      window.sessionStorage.getItem("expireYear"),
      typeof window !== "undefined" && window.sessionStorage.getItem("cvc"),
      famRate,
      options,
      selectedPlan.insurer,
      quotes,
      typeof window !== "undefined" && window.sessionStorage.getItem("studentNumber"),
    )
    dispatch(setPayment(purchasePayload, selectedPlan.insurer))
    setSubmitted(true)
  }

  const onMakeDestinationPurchase = (result) => {
    console.log(result);

    // moneris response
    if (result?.data?.responseCode) {
      if (!result.data.responseCode.includes('001')) {
        setPurchaseFailed(true);
        setDestinationResponse({ errorReason: result?.data?.errorMessage });
      } else {
        const purchasePayload = setPurchasePayload(
          typeof window !== "undefined" && window.sessionStorage.getItem("address"),
          typeof window !== "undefined" && window.sessionStorage.getItem("city"),
          typeof window !== "undefined" &&
          window.sessionStorage.getItem("province"),
          typeof window !== "undefined" &&
          window.sessionStorage.getItem("postalCode"),
          typeof window !== "undefined" && window.sessionStorage.getItem("email"),
          typeof window !== "undefined" && window.sessionStorage.getItem("phone"),
          travelPlan,
          travellers,
          coverage,
          deductible,
          purchaseConfirmation,
          result.data.cardName, // card name
          result.data.dataKey, // card number
          '01', // expire month
          '01', // expire year
          result?.data?.bin || '', // cvc,
          famRate,
          options,
          selectedPlan.insurer,
          quotes,
          typeof window !== "undefined" && window.sessionStorage.getItem("studentNumber"),
        );

        dispatch(setPayment(purchasePayload, selectedPlan.insurer))
        setSubmitted(true)
      }
    }
  }

  useEffect(() => {
    setPurchaseFailed(false);

    if (selectedPlan === null) return
    if (
      purchaseResult[selectedPlan.insurer] &&
      Object.keys(purchaseResult[selectedPlan.insurer]).length !== 0 &&
      purchaseResult[selectedPlan.insurer].error === null
    )
      navigate("/get-quote-purchase/success")
    if (
      !purchaseResult[selectedPlan.insurer] ||
      (Object.keys(purchaseResult[selectedPlan.insurer]).length !== 0 &&
        purchaseResult[selectedPlan.insurer].error !== null)
    ) {
      setSubmitted(false);
      setPurchaseFailed(true);
      window.scrollTo(0, 0);
      // navigate("/get-quote-purchase/fail")
      /*
      We are unable to process your payment for the plan shown below. Please contact your broker to complete the transaction.
      The possible reason of this failure might be: Sorry, system failure. Please notify technical support.
      */
    }
  }, [purchaseResult])

  return (
    <LayoutQuoting>
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidPlan && isValidPaymentInfo ? (
          <>
            <Title
              title={intl.formatMessage({ id: "purchase.title" })}
              fontColor="var(--clr-primary-300)"
            />
            <SectionCenter>
              <div
                css={css`
                  display: grid;
                  row-gap: 1.5rem;
                `}
              >
                {(purchaseFailed && apiCallStatus && apiCallStatus.errorReason) && (
                  <div
                    css={css`
                    display: grid;
                    row-gap: 1.5rem;
                    justify-items: center;
                  `}
                  >
                    <p
                      css={css`
                      max-width: 700px;
                      margin-bottom: 1rem;
                      font-size: 1rem;
                      font-weight: bold;
                      letter-spacing: var(--letter-spacing-sm);
                      line-height: 1.75;
                    `}
                    >
                      {intl.formatMessage({ id: "fail.string" })}
                    </p>
                    {apiCallStatus && apiCallStatus.errorReason && (
                      <p
                        css={css`
                        max-width: 700px;
                        margin-bottom: 2rem;
                        font-size: 1rem;
                        font-weight: bold;
                        letter-spacing: var(--letter-spacing-sm);
                        line-height: 1.75;
  
                        span {
                          color: var(--clr-primary-400);
                        }
                      `}
                      >
                        The possible reason of this failure might be:{" "}
                        <span>{apiCallStatus.errorReason}</span>
                      </p>
                    )}
                  </div>
                )}

                {(purchaseFailed && destinationResponse) && (
                  <div
                    css={css`
                    display: grid;
                    row-gap: 1.5rem;
                    justify-items: center;
                  `}
                  >
                    <p
                      css={css`
                      max-width: 700px;
                      margin-bottom: 1rem;
                      font-size: 1rem;
                      font-weight: bold;
                      letter-spacing: var(--letter-spacing-sm);
                      line-height: 1.75;
                    `}
                    >
                      {intl.formatMessage({ id: "fail.string" })}
                    </p>
                    {destinationResponse && destinationResponse?.errorReason && (
                      <p
                        css={css`
                        max-width: 700px;
                        margin-bottom: 2rem;
                        font-size: 1rem;
                        font-weight: bold;
                        letter-spacing: var(--letter-spacing-sm);
                        line-height: 1.75;
  
                        span {
                          color: var(--clr-primary-400);
                        }
                      `}
                      >
                        The possible reason of this failure might be:{" "}
                        <span>{destinationResponse?.errorReason}</span>
                      </p>
                    )}
                  </div>
                )}

                {selectedPlan && <>
                  <PlanDisplayCard />
                  {selectedPlan?.insurer === 'destination'
                    ? <PaymentCardDestination submitted={submitted} onPurchase={onMakeDestinationPurchase} />
                    : <PaymentCard />
                  }
                </>}
              </div>

              <div
                css={css`
                    display: flex;
                    justify-content: center;
                    margin-top: 4rem;
                  `}
              >
                {selectedPlan?.insurer !== 'destination' &&
                  <PrimaryButton
                    textSize="0.875rem"
                    textTransform="capitalize"
                    maxWidth="none"
                    margin="0 4rem 0 0"
                    onClick={onMakePurchase}
                    disabled={submitted}
                  >
                    {submitted ? (
                      <span>
                        <IconAnimated />
                      </span>
                    ) : (
                      intl.formatMessage({ id: "btn.purchase" })
                    )}
                  </PrimaryButton>
                }
                <SecondaryButton
                  buttonColor="var(--clr-neutral-400)"
                  textSize="0.875rem"
                  margin="none"
                  onClick={() => navigate("/get-quote-purchase/contact-info")}
                >
                  {intl.formatMessage({ id: "btn.goback" })}
                </SecondaryButton>
              </div>
            </SectionCenter>
          </>
        ) : (
          <GoBack
            backToLink="/get-quote-policies"
            notFilledOut={
              !isValidPlan
                ? intl.formatMessage({ id: "redirect.missingItem1" })
                : intl.formatMessage({ id: "redirect.missingItem4" })
            }
            text={
              !isValidPlan
                ? intl.formatMessage({ id: "redirect.text1" })
                : intl.formatMessage({ id: "redirect.text2" })
            }
            actionText={
              !isValidPlan
                ? intl.formatMessage({ id: "redirect.action1" })
                : intl.formatMessage({ id: "redirect.action2" })
            }
          />
        )}
      </section>
    </LayoutQuoting>
  )
}

const mapStateToProps = ({
  selectedPlan,
  canProceedFromUI,
  travelPlan,
  travellers,
  coverage,
  deductible,
  purchaseConfirmation,
  purchaseResult,
  famRate,
  options,
  quotes,
  apiCallStatus
}) => ({
  selectedPlan,
  canProceedFromUI,
  travelPlan,
  travellers,
  coverage,
  deductible,
  purchaseConfirmation,
  purchaseResult,
  famRate,
  options,
  quotes,
  apiCallStatus
})

const IconAnimated = styled(RiLoader5Line)`
  font-size: 1rem;
  color: var(--clr-neutral-100);

  animation: spin 0.75s linear infinite;
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export default connect(mapStateToProps, { getCurrentPath })(Payment)
